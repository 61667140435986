<template>
  <div class="bottom_menu text-center">
    <v-bottom-navigation :value="value" color="black" grow style="z-index: 100">
      <v-btn @click="bottomChange(0)" plain class="align-self-center">
        <span class="">홈</span>
        <span>
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <template v-if="value !== 0">
              <path
                d="M2.65 9.43404C2.65 8.97144 2.88687 8.54105 3.27768 8.29353L11.5452 3.05743C11.8229 2.88156 12.1771 2.88156 12.4548 3.05743L20.7223 8.29353C21.1131 8.54105 21.35 8.97144 21.35 9.43404V19.5C21.35 19.9694 20.9694 20.35 20.5 20.35H3.5C3.03056 20.35 2.65 19.9694 2.65 19.5V9.43404Z"
                stroke="#888888"
                stroke-width="1.3"
              />
              <path
                d="M9.65 14C9.65 13.2544 10.2544 12.65 11 12.65H13C13.7456 12.65 14.35 13.2544 14.35 14V20.35H9.65V14Z"
                stroke="#888888"
                stroke-width="1.3"
              />
            </template>

            <template v-else>
              <svg
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 9.43404C2 8.7487 2.35091 8.11109 2.9299 7.7444L11.1974 2.5083C11.6875 2.19794 12.3125 2.19794 12.8026 2.5083L21.0701 7.7444C21.6491 8.11109 22 8.7487 22 9.43404V19.5C22 20.3284 21.3284 21 20.5 21H3.5C2.67157 21 2 20.3284 2 19.5V9.43404Z"
                  fill="#222222"
                />
                <path
                  d="M9 14C9 12.8954 9.89543 12 11 12H13C14.1046 12 15 12.8954 15 14V21H9V14Z"
                  fill="white"
                />
              </svg>
            </template>
          </svg>
        </span>
      </v-btn>

      <v-btn @click="bottomChange(1)" plain class="align-self-center">
        <span>판매</span>
        <span>
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <template v-if="value !== 1">
              <rect
                x="2.65"
                y="3.65"
                width="17.7"
                height="14.7"
                rx="1.35"
                stroke="#888888"
                stroke-width="1.3"
              />
              <line
                x1="2"
                y1="7.35"
                x2="21"
                y2="7.35"
                stroke="#888888"
                stroke-width="1.3"
              />
              <line
                x1="2"
                y1="11.35"
                x2="21"
                y2="11.35"
                stroke="#888888"
                stroke-width="1.3"
              />
              <line
                x1="5.65"
                y1="14.35"
                x2="8.35"
                y2="14.35"
                stroke="#888888"
                stroke-width="1.3"
                stroke-linecap="round"
              />
            </template>

            <template v-else>
              <rect x="2" y="3" width="19" height="16" rx="2" fill="#222222" />
              <line
                x1="2"
                y1="7.35"
                x2="21"
                y2="7.35"
                stroke="white"
                stroke-width="1.3"
              />
              <line
                x1="2"
                y1="11.35"
                x2="21"
                y2="11.35"
                stroke="white"
                stroke-width="1.3"
              />
              <line
                x1="5.65"
                y1="14.35"
                x2="8.35"
                y2="14.35"
                stroke="white"
                stroke-width="1.3"
                stroke-linecap="round"
              />
            </template>
          </svg>
        </span>
      </v-btn>

      <!-- <v-btn @click="bottomChange(2)">
              <span>관심상품</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.564" height="17.166" viewBox="0 0 19.564 17.166">
                  <path :class="value==2 ? 'bottom_active':'bottom_noactive'" class="cls-1 svg_stroke" d="M19.64 5.944a4.937 4.937 0 0 0-6.984 0L11.7 6.9l-.952-.952a4.938 4.938 0 1 0-6.984 6.984l.952.952 6.984 6.979 6.984-6.984.952-.952a4.937 4.937 0 0 0 0-6.984z" transform="translate(-1.923 -4.097)"/>
              </svg>
  
          </v-btn> -->

      <!-- <v-btn @click="bottomChange(2)" plain class="align-self-center">
        <span>제휴/가맹</span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="19.206" height="17.161" viewBox="0 0 19.206 17.161">
            <path :class="value == 2 ? 'bottom_active' : 'bottom_noactive'" class="cls-1"
              d="M9.352 10l.809-5.368H5.954L4.2 9.229a1.613 1.613 0 0 0-.083.511 2.393 2.393 0 0 0 2.629 2.045A2.494 2.494 0 0 0 9.352 10zm3.964 1.789a2.393 2.393 0 0 0 2.629-2.049c0-.042 0-.084-.005-.124l-.52-4.989h-4.207l-.522 4.985v.128a2.393 2.393 0 0 0 2.629 2.045zm5.113 1.07V16.9H8.2v-4.039a4.3 4.3 0 0 1-1.461.254 4.216 4.216 0 0 1-.584-.05v6.492a1.435 1.435 0 0 0 1.43 1.432h11.457a1.436 1.436 0 0 0 1.432-1.432v-6.492a4.393 4.393 0 0 1-.584.05 4.249 4.249 0 0 1-1.461-.26zm4.008-3.626l-1.76-4.6h-4.205l.808 5.36a2.488 2.488 0 0 0 2.611 1.8 2.393 2.393 0 0 0 2.628-2.053 1.642 1.642 0 0 0-.082-.511z"
              transform="translate(-3.713 -4.227)" />
          </svg>
        </span>
      </v-btn> -->

      <v-btn @click="bottomChange(2)" plain class="align-self-center">
        <span>내정보</span>
        <span>
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <template v-if="value !== 2">
              <path
                d="M16.5032 11.9191L16.5053 11.9198C18.1606 12.5226 19.35 14.1201 19.35 15.9825V19.35H2.65V15.9825C2.65 13.5887 4.58983 11.65 6.98815 11.65H15.0119C15.5396 11.65 16.0357 11.747 16.5032 11.9191Z"
                stroke="#888888"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.35 6C14.35 7.8524 12.8524 9.35 11 9.35C9.1476 9.35 7.65 7.8524 7.65 6C7.65 4.1476 9.1476 2.65 11 2.65C12.8524 2.65 14.35 4.1476 14.35 6Z"
                stroke="#888888"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </template>

            <template v-else>
              <path
                d="M16.7277 11.3091C16.1933 11.1124 15.6213 11 15.0119 11H6.98815C4.23154 11 2 13.229 2 15.9825V20H20V15.9825C20 13.8378 18.6311 12.0021 16.7277 11.3091Z"
                fill="#222222"
              />
              <path
                d="M15 6C15 8.21138 13.2114 10 11 10C8.78862 10 7 8.21138 7 6C7 3.78862 8.78862 2 11 2C13.2114 2 15 3.78862 15 6Z"
                fill="#222222"
              />
            </template>
          </svg>
        </span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  components: {},
  data() {
    return {
      value: 0,
    };
  },
  created() {
    this.setInit();
  },
  watch: {
    //해당 라우트에서 주소가 바꼈을시 호출됨
    $route(to) {
      let path = to.path;

      if (path == "/home/home") {
        this.value = 0;
      } else if (path == "/sale/test") {
        this.value = 1;
        // }else if(path == '/sale/sale'){
        //     // this.value = 1;
        //     console.log('......ffff..........................')
        // }else if(path == '/favorite'){
        //     this.value = 2;
        // } else if (path == '/partner') {
        //   this.value = 2;
      } else if (path == "/myinfo") {
        // this.value = 3;
        this.value = 2;
      }
    },
  },

  mounted() {
    this.$EventBus.$on("openSellTab", () => {
      this.bottomChange(1);
    });
  },

  methods: {
    setInit() {
      let main_page = this.$store.getters.getMainPage;
      // console.log('main_page State........................................: ', main_page)
      if (main_page == null) {
        this.bottomChange(0);
      } else {
        this.bottomChange(main_page);
      }
    },

    bottomChange(value) {
      let params = this.$route.params.gift;
      // console.log('bottomChangee params........................................: ', params)
      this.value = value;
      let path = null;

      if (value == 0) {
        path = `/home/${params}`;
      } else if (value == 1) {
        path = "/sale";
        // }else if(value == 2){
        //     path = '/favorite';
      } else if (value == 2) {
        //   path = '/partner';
        // } else if (value == 3) {
        path = "/myinfo";
      }

      this.setMainPage(value, path);

      if (this.$route.path !== path) {
        // this.$router.replace(path);
        let newpath = path.replace("/", "");
        let data = "test1";
        // if(newpath === 'sale')this.$router.replace({name:newpath, params:{guid: 'test'}});
        if (newpath === "sale") this.$router.replace(`${path}/${data}`);
        else this.$router.replace(path);

        // this.$router.go(-1)
        // var currentUrl = window.location.pathname;
        // window.location.replace("http://localhost:8080")
      }
    },

    setMainPage(value, path) {
      // console.log(`setMainPage value : ${value}   path : ${path}`)
      this.$store.commit({
        type: "setCurrentRoute",
        route: path,
      });
      this.$store.commit({
        type: "setMainPage",
        main_page: value,
      });
      this.$store.commit({
        type: "setMainPath",
        main_path: path,
      });
    },
  },
};
</script>

<style lang="scss">
// .v-bottom-navigation.v-item-group{
.v-item-group.v-bottom-navigation {
  // border-radius: 20px 20px 0 0;
  background-color: none;
  // justify-content: center!important;
}

.cls-1 {
  fill: none;
  stroke-width: 0.9px;
}

.svg_stroke {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.bottom_active {
  stroke: #000;
  fill: #000;
}

.bottom_noactive {
  stroke: #e0e0e0;
}

// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom_menu {
  position: fixed;
  width: 101%;
  max-width: 395px;
  height: 80px;
  background: #fff;
  display: flex;
  bottom: 0;
  z-index: 1001;

  .v-bottom-navigation {
    height: 100% !important;
    box-shadow: 0px -5px 15px rgb(0 0 0 / 5%) !important;

    button {
      flex: 1;
      @include flex-center();
      flex-direction: column;
      gap: 8px;
      cursor: pointer;

      p {
        font-size: 0.625rem;
        color: #666;
      }
    }
  }
}
</style>
